import React, { lazy, Suspense } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'
import {
  initializeState,
  getSelectOption,
  getMultiSelectOption,
  getSelectOptions,
  validateForm,
  renderDefinition,
  renderDateInput,
  renderTextInput,
  renderSelectInput,
  renderAddressInput,
  renderPhoneInput,
  renderTextAreaInput,
  renderTable,
  showDate,
  renderCustomerLookup,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getProductMap, getProductOptions } from 'actions/product'
import { getRecipients, handleDelete } from 'actions/ticket'
import { getLocation, parseRecipient, fetchLocations } from 'actions/location'
import { Button, Center, DataPlaceholder, LoadingIcon } from 'components/core'
import { Select, NumberInput } from 'components/form'
import { MdEdit, MdDelete } from 'react-icons/md'
import { filterLocations } from 'utilities/permission'
import { updateImages } from 'actions/image'
import { Box, Flex } from 'reflexbox'
import { ImageThumb } from 'components/file'
import { today } from 'utilities/date'
import { urlencode } from 'utilities/app'

const moduleName = 'refund'
const url = process.env.REACT_APP_STATIC_URL
const ImageDropzone = lazy(() => import('components/file/ImageDropzone'))

export const initialState = (value = {}, data = {}, message) => {
  const recipients = getRecipients(value.recipients, message)
  const sales = data.sales
  const staffs = data.staffs
  const id = value.id || ''
  const parentId = value.parentId || ''
  const extra = value.extra || {}
  let dealerId = value.fromLocationId || value.toLocationId
  let dealerName = value.fromLocationName || value.toLocationName
  if (value.dispatchId) {
    dealerId = value.toLocationId
    dealerName = value.toLocationName
  }
  const { oldRcvdItems, rcvdItems, ticketItems, adjustItems } =
    groupTicketItems(
      value.ticketItems,
      parentId,
      value.dispatchId,
      value.profile,
    )
  const resultItems = getResultItems(
    rcvdItems,
    ticketItems,
    data.productVariants,
  )

  let products = data.productVariants

  if (parentId) {
    products = rcvdItems.map((item) => ({
      id: item.productVariantId,
      name: item.productVariantName,
      sku: item.sku,
    }))
  }

  return {
    isReady: value.isReady || false,
    id,
    locked: value.locked || false,
    parentId,
    dispatchId: value.dispatchId,
    status: value.status || 'PENDING',
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldRcvdItems: oldRcvdItems || [],
    rcvdItems: rcvdItems || [],
    ticketItems: ticketItems || [],
    resultItems: resultItems || [],
    adjustItems: adjustItems || [],
    inputValues: getInitialInput(),
    editValues: {},
    warehouses: data.warehouses,
    warehouseGroups: data.warehouseGroups,
    warehouseGroupName: extra.warehouseGroupName,
    dealers: data.dealers,
    recipients,
    billings: data.billings,
    sales,
    salesName: extra.salesName || [],
    staffs,
    staffName: extra.staffName || [],
    products: products || [],
    productMap: getProductMap(products),
    productOptions: getProductOptions(products || []),
    customerId: extra.customerId,
    images: extra.images || [],
    imagesToAdd: [],
    imagesToDel: [],
    ...initializeState({
      transDate: parentId && !id ? '' : showDate(value.transDate),
      ticketNo: value.ticketNo || '',
      dealerId: getSelectOption(data.dealers, dealerId, 'id', 'name'),
      dealerName: dealerName || '',
      warehouseGroupId: getSelectOption(
        data.warehouseGroups,
        extra.warehouseGroupId,
      ),
      recipientId: getSelectOption(recipients, extra.recipientId, 'id', 'name'),
      recipientName: extra.recipientName || '',
      recipientContact: extra.recipientContact || '',
      recipientAddress: extra.recipientAddress || {},
      recipientPhone: extra.recipientPhone || '',
      recipientCellphone: extra.recipientCellphone || '',
      billingId: getSelectOption(data.billings, extra.billingId),
      billingName: extra.billingName || '',
      salesId: getMultiSelectOption(sales, extra.salesId),
      staffId: getSelectOption(staffs, extra.staffId),
      memo: extra.memo || '',
      trackingNo: value.trackingNo || '',
    }),
  }
}

function getInitialInput() {
  return {
    productVariantId: '',
    productVariantName: '',
    balance: 0,
    quantity: 0,
  }
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  // warehouseGroupId: [{ type: 'required', message: 'error.required' }],
  dealerId: [{ type: 'required', message: 'error.required' }],
  recipientId: [{ type: 'required', message: 'error.required' }],
  recipientContact: [{ type: 'required', message: 'error.required' }],
  recipientAddress: [
    { type: 'required', message: 'error.required' },
    { type: 'address', message: 'error.invalidAddress' },
  ],
}

export const fields = ({
  profile,
  format,
  app,
  session,
  data,
  state,
  setState,
  message,
  action,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const phoneInput = (inputProps) =>
    renderPhoneInput({ ...commonProps, ...inputProps })
  const addressInput = (inputProps) =>
    renderAddressInput({ ...commonProps, ...inputProps, message })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })
  const customerLookup = (inputProps) =>
    renderCustomerLookup({ ...commonProps, ...inputProps })

  const isOwner = app.state.staff?.staffType === 'OWNER'

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    parentId: definition({
      id: 'parentId',
      show: !!state.parentId,
      label: 'field.parentId',
      href: getParentUrl(state.parentId),
    }),
    transDate: dateInput({ id: 'transDate', min: '1970-01-01' }),
    ticketNo: textInput({ id: 'ticketNo' }),
    billing: selectInput({
      id: 'billingId',
      valKey: 'billingName',
      isClearable: false,
      options: state.billings,
    }),
    dealer: selectInput({
      profile:
        (state.dispatchId || state.parentId) && !isOwner ? 'view' : profile,
      id: 'dealerId',
      valKey: 'dealerName',
      isClearable: false,
      options: getSelectOptions(state.dealers),
      callback: ({ value }) =>
        onDealerChange(state, app, session, message, value),
    }),
    warehouseGroup: selectInput({
      id: 'warehouseGroupId',
      valKey: 'warehouseGroupName',
      isClearable: false,
      options: state.warehouseGroups,
      callback: () => {
        const ticketItems = [...state.ticketItems].map((item) => {
          item.srcLocationId = null
          item.srcLocationName = null
          return item
        })
        return { ticketItems }
      },
    }),
    recipient: selectInput({
      id: 'recipientId',
      valKey: 'recipientName',
      isClearable: false,
      options: getSelectOptions(state.recipients),
      callback: (item) => {
        const recipient = getLocation(state.recipients, item?.value)
        return parseRecipient(recipient, state)
      },
    }),
    recipientContact: customerLookup({
      id: 'recipientContact',
      href: state.customerId
        ? `${process.env.REACT_APP_STOCK_URL}/customer/${state.customerId}/view`
        : null,
      target: '_blank',
      callback: (value) => {
        setState({
          ...state,
          customerId: value.id || '',
          recipientContact: value.name || '',
          recipientPhone: value.phone || '',
          recipientCellphone: value.cellphone || '',
          recipientAddress: value.address || {},
        })
        return true
      },
    }),
    recipientAddress: addressInput({ id: 'recipientAddress' }),
    recipientPhone: phoneInput({ id: 'recipientPhone' }),
    recipientCellphone: phoneInput({
      id: 'recipientCellphone',
      type: 'cellphone',
    }),
    sales: selectInput({
      id: 'salesId',
      valKey: 'salesName',
      isClearable: false,
      isMulti: true,
      options: state.sales,
    }),
    memo: textAreaInput({ id: 'memo' }),
    staff: selectInput({
      id: 'staffId',
      valKey: 'staffName',
      isClearable: false,
      options: state.staffs,
    }),
    trackingNo: textInput({ id: 'trackingNo' }),
    product: tableInput({
      id: 'rcvdItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'refund.field.spu',
          renderInput: ({ row }) => {
            if (row.id) return row.productVariantName
            return (
              <Select
                isClearable={false}
                options={state.productOptions}
                onChange={() => ({ quantity: 1 })}
              />
            )
          },
          getValue: (row) => {
            return getSelectOption(state.productOptions, row.productVariantId)
          },
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          width: '96px',
          align: 'right',
          renderInput: ({ row }) => (
            // <NumberInput min={Math.min(1, row?.balance)} max={row?.balance} />
            <NumberInput />
          ),
          // defaultInputValue: 1,
        },
      ],
      showAddInput: profile !== 'view' && !state.parentId,
      showDeleteIcon: profile !== 'view',
      inputValues: state.inputValues,
      editValues: state.editValues,
      onInputChange: (value) => setState({ ...state, inputValues: value }),
      onEditChange: (value) => setState({ ...state, editValues: value }),
      onAdd: ({ row }) => {
        const { productVariantName, quantity } = row
        if (!productVariantName.value) return

        const rcvdItems = cloneDeep(state.rcvdItems)
        rcvdItems.push({
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          quantity: parseInt(quantity),
        })
        const inputValues = getInitialInput()
        setState({ ...state, rcvdItems, inputValues })
      },
      onEdit: ({ row, index }) => {
        const { productVariantName } = row
        if (!productVariantName.value) return

        const rcvdItems = cloneDeep(state.rcvdItems)
        rcvdItems[index].quantity = parseInt(row.quantity)
        setState({ ...state, rcvdItems })
      },
      onDelete: ({ index }) => {
        const rcvdItems = cloneDeep(state.rcvdItems)
        rcvdItems.splice(index, 1)
        setState({ ...state, rcvdItems })
      },
    }),
    resultProduct: tableInput({
      id: 'resultItems',
      columns: [
        {
          id: 'productVariantName',
          label: 'refund.field.spu',
          render: ({ row }) =>
            row.comboQuantity
              ? `${row.productVariantName} x${row.comboQuantity}`
              : row.productVariantName,
        },
        {
          id: 'quantity',
          label: 'refund.field.appliedQuantity',
          align: 'right',
          renderHtml: ({ row }) => (
            <Box lineHeight="24px" color={getQuantityColor(row, state)}>
              {row.comboParentId ? '\u2014' : row.quantity}
            </Box>
          ),
          render: ({ row }) => (row.comboParentId ? '\u2014' : row.quantity),
        },
        {
          id: 'transDate',
          label: 'field.date',
          renderHtml: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems.map((item, index) => (
              <Box key={index} lineHeight="24px">
                {showDate(item.transDate)}
              </Box>
            ))
          },
          render: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems
              .map((item) => showDate(item.transDate))
              .join('<br/>')
          },
        },
        {
          id: 'type',
          label: 'refund.field.type',
          renderHtml: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems.map((item, index) => (
              <Box key={index}>
                {message({ id: `refund.type.${item.extra.type}` })}
              </Box>
            ))
          },
          render: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems
              .map((item) => message({ id: `refund.type.${item.extra.type}` }))
              .join('<br/>')
          },
        },
        {
          id: 'toLocationName',
          label: 'refund.field.warehouseId',
          renderHtml: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems.map((item, index) => (
              <Box
                key={index}
                lineHeight="24px"
                color={item.toLocationName ? 'dark.1' : 'light.3'}
              >
                {item.toLocationName || '\u2014'}
              </Box>
            ))
          },
          render: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems
              .map((item) => item.toLocationName || '\u2014')
              .join('<br/>')
          },
        },
        {
          id: 'itemQuantity',
          label: 'refund.field.quantity',
          align: 'right',
          renderHtml: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems.map((item, index) => (
              <Box key={index} lineHeight="24px">
                {item.quantity}
              </Box>
            ))
          },
          render: ({ row }) => {
            const resultItems = row.resultItems || []
            return resultItems.map((item) => item.quantity).join('<br/>')
          },
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          show: profile === 'view',
          render: ({ row, index }) => (
            <Button
              variant="icon"
              icon={<MdEdit />}
              onClick={() =>
                action.handleReceivedOpen({
                  ...row,
                  ticketId: state.id,
                  hash: state.hash,
                  index,
                })
              }
            />
          ),
        },
      ],
    }),
    adjustProduct: tableInput({
      id: 'adjustItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'refund.field.spu',
        },
        {
          id: 'toLocationName',
          label: 'refund.field.warehouseId',
        },
        {
          id: 'quantity',
          label: 'refund.field.quantity',
          align: 'right',
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          show: profile === 'view',
          render: ({ row, index }) => (
            <>
              <Button
                mr={2}
                variant="icon"
                icon={<MdEdit />}
                onClick={() =>
                  action.handleAdjustOpen({
                    ...row,
                    ticketId: state.id,
                    hash: state.hash,
                    index,
                  })
                }
              />
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const item = {
                    open: true,
                    title: 'refund.title.deleteAdjust',
                    text: 'refund.message.deleteAdjust',
                    onSubmit: async () => {
                      const [ok] = await deleteAdjustItem({
                        session,
                        app,
                        state,
                        id: row.id,
                      })
                      if (ok) {
                        const resp = await getData({
                          app,
                          session,
                          id: state.id,
                          parentId: state.parentId,
                          profile,
                        })
                        setState(initialState(resp, data, message))
                      }
                      return ok
                    },
                  }
                  session.dispatch({ type: CONFIRM_SET, item })
                }}
              />
            </>
          ),
        },
      ],
    }),
    images: renderImageField({
      format,
      profile,
      app,
      session,
      state,
      setState,
      action,
    }),
  }
}

function getParentUrl(parentId) {
  if (!parentId) return ''

  const prefix = parentId.substring(0, 2)
  if (prefix === 'DP') return `/dispatch/${parentId}/view`
  return `/defer/${parentId}/view`
}

function getQuantityColor(row, state) {
  const { productMap } = state
  if (row.comboParentId) return 'light.3'

  const product = productMap[row.productVariantId] || {}

  if (product.type === 'SINGLE') {
    const filled = row.quantity === getItemTotal(row.resultItems)
    return filled ? 'dark.1' : 'error.1'
  }

  const isEqual = isQuantityEqual(row, productMap)
  return isEqual ? 'dark.1' : 'error.1'
}

function isQuantityEqual(row, productMap) {
  const itemTotal = getItemTotal(row.resultItems)
  const childItems = row._subRows

  if (!childItems || childItems.length === 0) {
    return row.quantity === itemTotal
  }

  const balance = row.quantity - itemTotal
  const singleItems = productMap[row.productVariantId].childVariants
  if (singleItems.length !== childItems.length) return false

  return singleItems.every((singleItem) => {
    const itemTotal = childItems
      .filter((item) => item.productVariantId === singleItem.id)
      .reduce((result, item) => {
        result += getItemTotal(item.resultItems)
        return result
      }, 0)
    return balance * singleItem.quantity === itemTotal
  })
}

function getItemTotal(resultItems) {
  if (!resultItems) return 0

  return resultItems.reduce((result, item) => {
    result += item.quantity
    return result
  }, 0)
}

function renderImageField({
  format,
  profile,
  app,
  session,
  state,
  setState,
  action,
}) {
  if (format === 'print') return { id: 'images' }
  if (profile === 'view') {
    return (
      <Flex flexWrap="wrap" alignContent="center">
        {state.images.length === 0 ? (
          <DataPlaceholder />
        ) : (
          state.images.map((item) => {
            const { merchantId } = app.state.staff
            const src = `${url}/${merchantId}/${state.id}/${urlencode(item)}`
            return (
              <ImageThumb
                key={item}
                src={src}
                onView={() => {
                  action.setPreviewOpen(true)
                  action.setPreviewImage({ src, alt: item })
                }}
              />
            )
          })
        )}
      </Flex>
    )
  }

  return (
    <Suspense
      fallback={
        <Center>
          <LoadingIcon />
        </Center>
      }
    >
      <ImageDropzone
        baseUrl={`${url}/${app.state.staff.merchantId}/${state.id}/`}
        value={state.images || []}
        onUpload={(files) => {
          const images = [...state.images, ...files]
          const imagesToAdd = [...state.imagesToAdd, ...files]
          setState({ ...state, images, imagesToAdd })
        }}
        onDelete={(file, index) => {
          const images = [...state.images]
          images.splice(index, 1)
          const imagesToDel = [...state.imagesToDel]
          if (!file.preview) imagesToDel.push(file)
          setState({ ...state, images, imagesToDel })
        }}
        onError={(errorMessages) =>
          errorMessages.forEach((item) => {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'error', message: item },
            })
          })
        }
        onDrag={(images) => {
          setState({ ...state, images })
        }}
      />
    </Suspense>
  )
}

export const handlers = ({
  state,
  setState,
  data,
  setData,
  session,
  app,
  history,
  message,
  id,
  parentId,
  dispatchId,
  profile,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ app, session })
    setData(resp)
  },
  handleLoad: async () => {
    if (!data.productVariants) return

    const resp = await getData({
      app,
      session,
      id,
      parentId,
      dispatchId,
      profile,
    })
    setState(initialState(resp, data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    if (state.rcvdItems.length === 0) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.product.required' },
      })
      return
    }

    const [ok, data] = id
      ? await editRefund(state, app, session)
      : await addRefund(state, app, session)
    if (!ok) return

    if (!id) id = data.addRefundTicket

    const imageOk = await updateImages(app, session, state, id)
    if (!imageOk) {
      if (!id) history.push(`/refund/${id}/edit`)
      return
    }

    history.push(`/refund/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmRefund({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id, parentId })
    setState(initialState(resp, data, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })
    return true
  },
  handleComplete: async () => {
    const { hash } = state
    const [ok] = await completeRefund({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id, parentId })
    setState(initialState(resp, data, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })
    return true
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('refund', { session, app, id, hash })
    if (!ok) return false

    history.push('/refund')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ app, state, message })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ app, state, message }) {
  const title = { id: 'refund.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const form = fields({
    profile: 'view',
    format: 'print',
    app,
    state,
    message,
  })
  const field = Object.values(form).filter(
    ({ id }) =>
      ![
        'rcvdItems',
        'ticketItems',
        'resultItems',
        'adjustItems',
        'images',
      ].includes(id),
  )
  const content = [
    { type: 'field', value: field },
    { type: 'subTitle', value: 'refund.section.product' },
    { type: 'list', value: form.product },
    { type: 'subTitle', value: 'refund.section.resultProduct' },
    { type: 'list', value: form.resultProduct },
    { type: 'subTitle', value: 'refund.section.adjustProduct' },
    { type: 'list', value: form.adjustProduct },
  ]
  return { title, header, content }
}

async function getInit({ app, session }) {
  const staffInput = { type: ['SALES', 'WAREHOUSE'] }
  const locationInput = {
    type: ['DEALER', 'COMPANY', 'WAREHOUSE', 'WAREHOUSE_GROUP'],
  }
  const productInput = { status: ['ACTIVE', 'INACTIVE'] }
  const variables = { locationInput, productInput, staffInput }
  const query = `
    query($locationInput: LocationQueryInput, $productInput: ProductQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
        type
      }
      productVariants(input: $productInput) {
        id
        name
        sku
        barcode
        type
        childVariants {
          id
          name
          quantity
        }
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const warehouses = []
  const warehouseGroups = []
  const dealers = []
  const billings = []
  const sales = []
  const staffs = []

  data.locations.forEach((item) => {
    if (item.type === 'WAREHOUSE_GROUP') {
      warehouseGroups.push({ label: item.name, value: item.id })
    }
  })

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      // case 'WAREHOUSE_GROUP':
      //   warehouseGroups.push(result)
      //   break
      case 'WAREHOUSE':
        warehouses.push(result)
        break
      case 'DEALER':
        dealers.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'BILLING') billings.push(result)
        break
      default:
        break
    }
  })

  data.staffs.forEach((item) => {
    const result = { label: item.name, value: item.id }
    switch (item.type) {
      case 'WAREHOUSE':
        staffs.push(result)
        break
      case 'SALES':
        sales.push(result)
        break
      default:
        break
    }
  })

  return {
    warehouseGroups,
    warehouses,
    dealers,
    billings,
    sales,
    staffs,
    productVariants: productVariants.map((item) => {
      if (item.status === 'INACTIVE') item.name = `${item.name} [停用]`
      return item
    }),
  }
}

async function getData({ app, session, id, parentId, dispatchId, profile }) {
  // if (profile === 'add') return {}

  const variables = { id: parentId || dispatchId || id }
  const query = `
    query($id: ID) {
      refundTicket(id: $id) {
        parentId
        ticketNo
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      refundTicketItems(ticketId: $id) {
        id
        ticketType
        transDate
        productVariantId
        toLocationId
        toLocationName
        productVariantName
        sku
        quantity
        balance
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { refundTicketItems } = data
  const ticket = data.refundTicket || {}
  const extra = ticket.extra || {}
  let recipients = []

  if (profile === 'edit' && ticket.fromLocationId) {
    const locationInput = { parentId: ticket.fromLocationId, type: ['STORAGE'] }
    recipients = await fetchLocations(app, session, locationInput)
  }

  if ((parentId || dispatchId) && ticket.toLocationId) {
    const locationInput = { parentId: ticket.toLocationId, type: ['STORAGE'] }
    recipients = await fetchLocations(app, session, locationInput)
  }

  return {
    profile,
    isReady: profile === 'edit',
    parentId: parentId || ticket.parentId,
    dispatchId,
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    locked: ticket.extra?.locked,
    transDate: parentId ? '' : id ? ticket.transDate : today(),
    ticketNo: ticket.ticketNo,
    // dealerId:
    //   parentId || dispatchId ? ticket.toLocationId : ticket.fromLocationId,
    // dealerName:
    //   parentId || dispatchId ? ticket.toLocationName : ticket.fromLocationName,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    // warehouseGroupId: extra.warehouseGroupId,
    // warehouseGroupName: extra.warehouseGroupName,
    // recipientId: parentId ? null : extra.recipientId,
    // recipientName: extra.recipientName,
    // recipientContact: extra.recipientContact,
    // recipientAddress: extra.recipientAddress,
    // recipientPhone: extra.recipientPhone,
    // recipientCellphone: extra.recipientCellphone,
    // customerId: extra.customerId,
    // billingId: extra.billingId,
    // billingName: extra.billingName,
    // salesId: extra.salesId,
    // salesName: extra.salesName,
    // staffId: extra.staffId,
    // staffName: extra.staffName,
    // memo: extra.memo,
    // images: extra.images,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems: refundTicketItems,
    recipients,
    trackingNo: extra.trackingNo,
  }
}

function groupTicketItems(refundTicketItems, parentId, dispatchId, profile) {
  const rcvdItems = []
  const ticketItems = []
  let adjustItems = []
  let oldRcvdItems = []

  if (!refundTicketItems) {
    return { oldRcvdItems, rcvdItems, ticketItems, adjustItems }
  }

  const revertItems = refundTicketItems.filter(
    (item) => item.extra?.isRevert && item.status === 'ACTIVE',
  )

  refundTicketItems
    .filter((item) => {
      if (item.status !== 'ACTIVE') return false
      if (dispatchId) return true
      if (item.ticketType === 'ADJUST') return false

      const { isPayment, isRevert } = item.extra || {}
      return !isPayment && !isRevert
    })
    .forEach((item) => {
      item.transDate = showDate(item.transDate)

      if (parentId && profile === 'add') {
        const revertQuantity = getRefundQuantity(revertItems, item.id)
        item.quantity = item.quantity - item.balance - revertQuantity
        if (item.quantity <= 0) return
        rcvdItems.push(item)
        return
      }

      if (item.extra.isReceived || dispatchId) {
        rcvdItems.push(item)
      } else {
        ticketItems.push(item)
      }
    })

  if (profile !== 'add') oldRcvdItems = cloneDeep(rcvdItems)
  adjustItems = refundTicketItems.filter((item) => item.ticketType === 'ADJUST')

  return { oldRcvdItems, rcvdItems, ticketItems, adjustItems }
}

function getResultItems(appliedItems, receivedItems, products) {
  if (!appliedItems) return []
  if (!products) return []

  const result = cloneDeep(appliedItems)
  const comboItems = getComboItems(result, products)
  const allResult = result.concat(comboItems)

  matchItemByParentId(allResult, receivedItems)
  matchItemByProductId(allResult, receivedItems)
  attachSubItemToParent(allResult)

  return result
}

function getComboMap(products) {
  return products
    .filter((item) => item.type === 'COMBO')
    .reduce((result, item) => {
      result[item.id] = item.childVariants
      return result
    }, {})
}

function getComboItems(result, products) {
  const comboMap = getComboMap(products)
  const comboItems = []

  result.forEach((item) => {
    const comboList = comboMap[item.productVariantId]
    if (comboList) {
      comboList.forEach((combo) => {
        const comboItem = cloneDeep(item)
        comboItem.quantity = item.quantity * combo.quantity
        comboItem.productVariantId = combo.id
        comboItem.productVariantName = combo.name
        comboItem.comboQuantity = combo.quantity
        comboItem.comboParentId = item.id
        comboItems.push(comboItem)
      })
    }
  })

  return comboItems
}

function matchItemByParentId(result, receivedItems) {
  receivedItems
    .filter((item) => !!item.extra?.parentItemId)
    .forEach((item) => {
      const appliedItem =
        result.find(
          (i) =>
            i.id === item.extra?.parentItemId &&
            i.productVariantId === item.productVariantId,
        ) || {}
      const resultItems = appliedItem.resultItems || []
      let resultItem = resultItems.find(
        (i) =>
          i.transDate === item.transDate &&
          i.extra?.type === item.extra?.type &&
          i.toLocationId === item.toLocationId,
      )

      if (resultItem) {
        resultItem.quantity += parseInt(item.quantity)
      } else {
        resultItems.push(cloneDeep(item))
      }

      appliedItem.resultItems = resultItems
    })
}

function matchItemByProductId(result, receivedItems) {
  receivedItems
    .filter((item) => !item.extra?.parentItemId)
    .forEach((item) => {
      const appliedList =
        result.filter((i) => i.productVariantId === item.productVariantId) || []
      const balanceMap = appliedList.reduce((result, i) => {
        result[i.id] = parseInt(i.quantity)
        return result
      }, {})

      appliedList.forEach((appliedItem) => {
        const balance = balanceMap[appliedItem.id]
        const quantity = Math.min(balance, parseInt(item.quantity))

        const resultItems = appliedItem.resultItems || []
        let resultItem = resultItems.find(
          (i) =>
            i.transDate === item.transDate &&
            i.extra?.type === item.extra?.type &&
            i.toLocationId === item.toLocationId,
        )

        if (resultItem) {
          resultItem.quantity += quantity
        } else {
          resultItems.push({ ...item, quantity })
        }

        balanceMap[appliedItem.id] -= quantity
        appliedItem.resultItems = resultItems
      })
    })
}

function attachSubItemToParent(result) {
  result
    .filter((item) => !!item.comboParentId)
    .forEach((item) => {
      const parentItem = result.find((i) => i.id === item.comboParentId)
      const _subRows = parentItem._subRows || []
      _subRows.push(item)
      parentItem._subRows = _subRows
    })
}

function getRefundQuantity(revertItems, id) {
  const revertItem = revertItems.find((ri) => ri.extra?.parentItemId === id)
  return revertItem?.quantity || 0
}

// function getRevertQuantity(revertItems, id) {
//   const revertItem = revertItems.find((ri) => ri.extra?.parentItemId === id)
//   return revertItem?.quantity || 0
// }

async function onDealerChange(state, app, session, message, dealerId) {
  const { dealers, billings } = state
  const ticketItems = [...state.ticketItems]
  const dealer = getLocation(dealers, dealerId)

  const locationInput = { parentId: dealerId, type: ['STORAGE'] }
  const recipients = await fetchLocations(app, session, locationInput)

  return {
    billingId: getSelectOption(billings, dealer?.extra.billingId),
    recipients: getRecipients(recipients, message),
    ticketItems,
  }
}

async function addRefund(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addRefundTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editRefund(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editRefundTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, parentId, hash, oldRcvdItems } = state
  const rcvdItems = state.rcvdItems.map((item) => {
    const extra = item.extra || {}
    extra.isReceived = true
    if (parentId && !extra.parentItemId) extra.parentItemId = item.id
    return {
      id: item.id,
      productVariantId: item.productVariantId,
      quantity: parseInt(item.quantity),
      extra,
    }
  })
  const isRcvdKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isRcvdValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    return true
  }
  const rcvdDiff = getDiff(
    oldRcvdItems,
    rcvdItems,
    isRcvdKeyEqual,
    isRcvdValEqual,
  )
  const images = state.images.map((item) => item.name || item)

  return {
    id,
    parentId,
    hash,
    ticketNo: state.ticketNo,
    transDate: state.transDate,
    fromLocationId: state.dealerId.value,
    extra: {
      ticketNo: state.ticketNo,
      warehouseGroupId: state.warehouseGroupId?.value,
      warehouseGroupName: state.warehouseGroupId?.label,
      recipientId: state.recipientId.value,
      recipientName: state.recipientId.label,
      recipientContact: state.recipientContact,
      recipientAddress: state.recipientAddress,
      recipientPhone: state.recipientPhone,
      recipientCellphone: state.recipientCellphone,
      customerId: state.customerId,
      billingId: state.billingId.value,
      billingName: state.billingId.label,
      salesId: state.salesId?.map((item) => item.value),
      salesName: state.salesId?.map((item) => item.label),
      staffId: state.staffId?.value,
      staffName: state.staffId?.label,
      memo: state.memo,
      itemsToAdd: rcvdDiff.added,
      itemsToEdit: rcvdDiff.modified.map((item) => item.after),
      itemsToDel: rcvdDiff.removed.map((item) => item.id),
      images,
      trackingNo: state.trackingNo,
    },
  }
}

async function confirmRefund({ session, app, id, hash }) {
  const input = { ticketType: 'REFUND', status: 'ACTIVE', hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmRefundTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function completeRefund({ session, app, id, hash }) {
  const input = { ticketType: 'REFUND', status: 'ACTIVE', hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      completeRefundTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

// async function deleteTicketItem({ session, app, state, id }) {
//   const input = { hash: state.hash, itemsToDel: [id] }
//   const variables = { id: state.id, input }
//   const query = `
//     mutation($id: ID!, $input: TicketInput!) {
//       editRefundTicket(id: $id, input: $input)
//     }
//   `
//   return request({ query, variables }, { session, app })
// }

async function deleteAdjustItem({ session, app, state, id }) {
  const variables = { ticketId: state.id, hash: state.hash, id }
  const query = `
    mutation($ticketId: ID!, $hash: String!, $id: ID!) {
      delRefundAdjust(ticketId: $ticketId, hash: $hash, id: $id)
    }
  `
  return request({ query, variables }, { session, app })
}
